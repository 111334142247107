<script setup lang="ts">
</script>

<template>
  <div class="h-screen w-screen items-center lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
    <div class="flex h-full items-center justify-center py-12">
      <div class="absolute left-2 top-2 flex items-center">
        <AppThemeSwitcher class="" />
        <AppButton icon="lucide:home" to="/" variant="ghost" shape="circle" />
      </div>
      <slot />
    </div>
    <div class="background-squares relative hidden h-full lg:block">
      <span>&nbsp;</span>
    </div>
  </div>
</template>
